import ScholarshhipIcon from "../icons/ScholarshipIcon";
import DocumentIcon from "../icons/DocumentIcon";
import LoanIcon from "../icons/LoanIcon";

export const customermenu = [
    // {
    //     id: 1,
    //     image: user,
    //     title: "My Profile",
    //     url: "/profile"
    // },
    // {
    //     id: 2,
    //     image: <ScholarshhipIcon />,
    //     title: "My Scholarships",
    //     url: "/myscholarship"
    // },
    // {
    //     id: 5,
    //     image: <DocumentIcon />,
    //     title: "My Documents",
    //     url: "/my-documents"
    // },
    // {
    //     id: 3,
    //     image: <LoanIcon />,
    //     title: "My Loans",
    //     url: "/myloan"
    // },
    // {
    //     id: 4,
    //     image: Settings,
    //     title: "Settings",
    //     url: "/"
    // },
    // {
    //     id: 5,
    //     image: logout,
    //     title: "Log Out",
    //     url:"/"
    // },
];