import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { FIRST_LOGIN, GET_PROFILE, IS_LOGIN, PHONE_NO } from "../Redux/actions/Login";
import { Storage } from "../utils/Storage";

const Protected = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { isLogin, isLoading } = useSelector((state) => state.login)

  const PhoneNo = Storage.get("mobile")
  const first_Login = Storage.get("first_Login")
  const otpCalled =  Storage.get("otpProfileCallWent");
  // console.log("protected---->",authenticated);

  useEffect(() => {
    if (PhoneNo) {
      if (first_Login) {
        dispatch(FIRST_LOGIN(first_Login))
      }
      if (isLogin) return
      if(!otpCalled) {
        dispatch(GET_PROFILE(PhoneNo));
      }
      dispatch(PHONE_NO(PhoneNo))
      dispatch(IS_LOGIN(true));
    } else {
      if (!isLogin) {
        dispatch(IS_LOGIN(false));
      }
    }
  }, [pathname, PhoneNo, first_Login, isLogin, isLoading, dispatch]);

  return <Outlet />;
};

export default Protected;
